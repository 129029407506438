import React from 'react';
import ReactDOM from 'react-dom';

// Modal component
const Modal = ({ isOpen, onClose, title, buttonLink, children }) => {
  if (!isOpen) return null;

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const onButtonClick = () => {
    window.open(buttonLink, '_blank');
  };

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal" onClick={stopPropagation}>
        <div className="modal-header">
          {title && <h2 className="modal-title">{title}</h2>}
          <div className="modal-buttons">
            {buttonLink && <button className="primary-button modal-button" onClick={onButtonClick}>
                  View item on BGG <i class="fa-solid fa-square-arrow-up-right"></i>
              </button>}
            <button className="close-button" onClick={onClose} aria-label="Close">
              ✕
            </button>
          </div>
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

export default Modal;
