import React from 'react';

const LoadingInline = ({message, progress, type}) => {
    // round progress to two decimal places
    progress = progress.toFixed(2);

    return (
        <div className="loading-inline-container">
            <div className="loading-inline-content">
                <span className="loading-message">{message}...</span>
                
                {type === 'progress' && (
                    <div className="progress-bar-container">
                        <div className="progress-bar">
                            <div className="progress" style={{width: `${progress}%`}}></div>                    
                        </div>
                        <span className="progress-value">{progress}%</span>
                    </div>
                )}
                
                {type === 'spinner' && (
                    <div className="loader"></div>
                )}
            </div>
        </div>
    );
}

export default LoadingInline;
