import React, { useState, useRef, useEffect } from 'react';
import { fetchData, fetchResponse, fetchGeeklists } from '../services';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { parseXml } from '../helpers';
import BBCodeParser from '../helpers/BBCodeRenderer';
import LoadingInline from './loading-inline.js';
import { findPriceAndType, findRemove, findSold, useBggUsers } from '../helpers';
import BggGameModal from './bgg-game-modal.js';
import { GlobalStateContext } from '../GlobalState';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

const BGG_BASE_URL = "https://boardgamegeek.com";


function GameLinkRenderer(props) {
    return (
      <a style={{ justifySelf: 'flex-start' }} href={BGG_BASE_URL.concat(props.data.item.href)} target="_blank" rel="noreferrer">{props.value}</a>
    );
}

function LeftAlignedCellRenderer(props) {
    return <span style={{ justifySelf: 'flex-start' }}>{props.value}</span>;
}

function BooleanCellRenderer(props) {
    return <span style={{ justifySelf: 'flex-start' }}>{props.value ? "True" : "False"}</span>;
}

const BggList = () => {
    const base_url = "/api/listitems";
    const xml_base_url = "/xmlapi2";

    const { geeklistMeta, setGeeklistMeta, geeklistItems, setGeeklistItems, usernameValue, setUsernameValue } = useContext(GlobalStateContext);

    const [wishlistData, setWishlistData] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalGameData, setModalGameData] = useState({});

    const [listIdValue, setlistIdValue] = useState('331341');
    const [isLoading, setIsLoading] = useState(false);
    const [progressValues, setProgressValues] = useState({message: "Loading", progress: 0, type: 'progress'});
    const [rowCount, setRowCount] = useState(0);
    const [isControlsExpanded, setIsControlsExpanded] = useState(true);
    const [isAboutExpanded, setIsAboutExpanded] = useState(false);
    const [isGeeklistBodyExpanded, setIsGeeklistBodyExpanded] = useState(false);
    const [geeklists, setGeeklists] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);

    const { findBggUser } = useBggUsers();
    const dropdownRef = useRef(null);

    // Fetch geeklists when component mounts
    useEffect(() => {
        const loadGeeklists = async () => {
            setIsLoading(true);
            const result = await fetchGeeklists();
            if (result && result.geeklists) {
                setGeeklists(result.geeklists);
            }
            setIsLoading(false);
        };
        
        loadGeeklists();
    }, []);

    // Handle click outside dropdown to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleExpanded = () => {
        setIsAboutExpanded(!isAboutExpanded);
    };

    const controlToggleExpanded = () => {
        setIsControlsExpanded(!isControlsExpanded);
    };

    const toggleGeeklistBodyExpanded = () => {
        setIsGeeklistBodyExpanded(!isGeeklistBodyExpanded);
    };

    const clearGeeklistSearchInput = () => {
        setSearchTerm('');
    };

    const gridRef = useRef();

    // Filter geeklists based on search term
    const getFilteredGeeklists = (term) => {
        if (!term.trim()) {
            return geeklists; // Show all geeklists when search is empty
        }
        
        // Filter by title or ID
        return geeklists.filter(geeklist => 
            geeklist.title.toLowerCase().includes(term.toLowerCase()) || 
            geeklist.id.includes(term)
        );
    };

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        
        // If the input is a number (geeklist ID), update listIdValue
        if (/^\d+$/.test(value)) {
            setlistIdValue(value);
        }
        
        // Always show dropdown when typing
        setShowDropdown(true);
    };

    const handleGeeklistSelect = (geeklist) => {
        setlistIdValue(geeklist.id);
        setSearchTerm(geeklist.title);
        setShowDropdown(false);
    };

    const filteredGeeklists = getFilteredGeeklists(searchTerm);

    const handleUsernameValue = (event) => {
      setUsernameValue(event.target.value);
    }

    const openModal = () => {
        setIsModalOpen(true);
    };

    // Define ButtonRenderer within BggList
    function ButtonRenderer(props) {
        const handleClick = () => {
            setModalGameData(props.data);
            openModal();
        };

        const viewClick = () => {
            window.open(BGG_BASE_URL.concat(props.data.href), '_blank');
        };

        return (
            <div className="button-container">
                <button onClick={handleClick}>
                    Summary
                </button>
                <button className="primary-button" onClick={viewClick}>
                    <i class="fa-solid fa-square-arrow-up-right"></i>
                </button>
            </div>
        );
    }

    // Defint DateRenderer within BggList
    function DateRenderer(props) {
        let date = new Date(props.value);
        // Format date to DD/MM/YYYY
        const dateString = date.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' });
        
        // // Format time as HH:MM AM/PM
        // const time = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
        // //display date on one line and time on another
        // return <span>{dateString} @ {time}</span>;

        return <span>{dateString}</span>;
    }

    function TimeRenderer(props) {
        let date = new Date(props.value);
        // Format time as HH:MM AM/PM
        const time = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
        return <span>{time}</span>;
    }

    function PriceRenderer(props) {
        if(props.value === "?" || props.value === "N/A" || props.value === "" || props.value === 0){ 
            return <span>{props.value}</span>;
        } else {
            return <span>${props.value}</span>;
        }
    }

    const isExternalFilterPresent = () => {
        return (wishlistData.length > 0);
    }

    const doesExternalFilterPass = (node) => {
        return wishlistData.find((id) => {
            return (id === node.data.item.id) ? true : false;
        })
    }

    const timeComparator = (time1, time2) => {
        // Convert the time strings to Date objects for comparison
        const date1 = new Date(time1);
        const date2 = new Date(time2);
        
        // Compare the Date objects
        if (date1 < date2) {
            return -1;
        } else if (date1 > date2) {
            return 1;
        } else {
            return 0;
        }
    };

    const dateComparator = (date1, date2) => {
        // Convert the time strings to Date objects for comparison
        const dateObj1 = new Date(date1).setHours(0, 0, 0, 0);
        const dateObj2 = new Date(date2).setHours(0, 0, 0, 0);
        
        // Compare the Date objects
        if (dateObj1 < dateObj2) {
            return -1;
        } else {
            return 1;
        }
    }

    const priceComparator = (price1, price2) => {
        if(price1 === '?') return 1;
        if(price2 === '?') return -1;
        return price1 > price2 ? 1 : -1;
    }

    const columnDefs = [
        { headerName: 'List Date', field: 'postdate', flex: 1, minWidth: 120, filter: 'agDateColumnFilter', comparator: dateComparator, cellRenderer: DateRenderer, width: 120 },
        { headerName: 'List Time', field: 'postdate', flex: 1, minWidth: 80, filter: true, comparator: timeComparator, cellRenderer: TimeRenderer },
        { headerName: 'Avg. Rating', field: 'stats.average', flex: 1, minWidth: 80, filter: 'agNumberColumnFilter', cellRenderer: LeftAlignedCellRenderer, width: 80 },
        { headerName: 'Name', field: 'item.name', flex: 1, minWidth: 120, filter: true, cellRenderer: GameLinkRenderer, width: 200 },
        { headerName: 'Price', field: 'price', flex: 1, minWidth: 80, filter: 'agNumberColumnFilter', comparator: priceComparator, cellRenderer: PriceRenderer},
        { headerName: 'Type', field: 'priceType', flex: 1, minWidth: 120, filter: true },
        { headerName: 'Seller', field: 'username', flex: 1, minWidth: 120, filter: true, cellRenderer: LeftAlignedCellRenderer },
        { headerName: 'Listing Info', 
            field:'body',
            flex: 1, 
            minWidth: 150,
            cellRenderer: ButtonRenderer, 
            cellRendererParams: {
                openModal: openModal
            }
        },
        { headerName: 'Sold', field: 'sold', flex: 1, minWidth: 120, filter: true, cellRenderer: BooleanCellRenderer, width: 100 },
        { headerName: 'Expansion', field: 'expansion', flex: 1, minWidth: 120, filter: true, cellRenderer: BooleanCellRenderer, width: 100 }
    ];

    const autoSizeStrategy = {
        type: 'fitCellContents',
        skipHeader: true
    };

    const updateRowCount = () => {
        setRowCount(gridRef.current?.api.getDisplayedRowCount()); 
        }

    const fetchDataAndProcess = async () => {
        let loadingMessage = "Loading geeklist items";
        setProgressValues({message: loadingMessage, progress: 0, type: "progress"});
        setIsLoading(true);

        try {
            let total = 0;
            let lastpage = 10;
            const newData = [];

            // Fetch first page to get pagination info
            const geeklistMetaUrl = `/api/geeklists/${listIdValue}`;
            const firstPageUrl = `${base_url}?listid=${listIdValue}&page=1`;
            const geeklistMetaResult = await fetchResponse(geeklistMetaUrl);
            const firstPageResult = await fetchData(firstPageUrl);
            setGeeklistMeta(geeklistMetaResult.data);

            if (!firstPageResult || !geeklistMetaResult) {
                console.error('BggList - Error fetching data for list ID:', listIdValue);
                setIsLoading(false);
                return;
            }

            if (!firstPageResult.data || !Array.isArray(firstPageResult.data)) {
                console.error('BggList - Invalid data format received:', firstPageResult);
                setIsLoading(false);
                return;
            }

            // Add first page data
            newData.push(...firstPageResult.data);

            // Get pagination info
            if (firstPageResult.pagination) {
                total = firstPageResult.pagination.total;
                lastpage = Math.ceil(total / 25);
            }

            // Update progress
            setProgressValues({
                message: loadingMessage, 
                progress: (1 / lastpage) * 100, 
                type: "progress"
            });

            // If there are more pages, fetch them in parallel
            if (lastpage > 1) {
                const pagePromises = [];
                
                // Create an array of promises for pages 2 to lastpage
                for (let page = 2; page <= lastpage; page++) {
                    const url = `${base_url}?listid=${listIdValue}&page=${page}`;
                    pagePromises.push(fetchData(url));
                }
                
                // Create an array to track completed pages
                let completedPages = 1; // Start with 1 for the first page already loaded
                
                // Use Promise.all with individual handlers to update progress as each promise completes
                const pagePromisesWithProgress = pagePromises.map((promise, index) => {
                    return promise.then(result => {
                        if (result && result.data) {
                            newData.push(...result.data);
                        }
                        
                        // Increment completed pages and update progress
                        completedPages++;
                        const progress = (completedPages / lastpage) * 100;
                        setProgressValues({message: loadingMessage, progress, type: "progress"});
                        
                        return result;
                    }).catch(error => {
                        console.error(`BggList - Error fetching page ${index + 2}:`, error);
                        
                        // Still increment completed pages and update progress even on error
                        completedPages++;
                        const progress = (completedPages / lastpage) * 100;
                        setProgressValues({message: loadingMessage, progress, type: "progress"});
                        
                        return null;
                    });
                });
                
                // Wait for all promises to complete
                await Promise.all(pagePromisesWithProgress);
            }

            if (newData.length === 0) {
                console.log('BggList - No data found for list ID:', listIdValue);
                setIsLoading(false);
                return;
            }

            // Filter out items where the auther indicated it should be removed!
            const filteredData = newData.filter(item => !findRemove(item.body));

            // Collect unique author IDs
            const uniqueAuthorIds = [...new Set(filteredData.map(item => item.author))];
            
            // Create a map to store user data
            const userMap = new Map();
            
            // Fetch user data in batches
            loadingMessage = "Finding usernames";
            setProgressValues({message: loadingMessage, progress: 0, type: "progress"});
            
            for (let i = 0; i < uniqueAuthorIds.length; i += 1) {
                await findBggUser(uniqueAuthorIds[i]).then(user => {
                    if (user) {
                        userMap.set(user.id, user);
                    }
                        
                    const progress = (i / uniqueAuthorIds.length) * 100;
                    setProgressValues({message: loadingMessage, progress, type: "progress"});
                    
                    return user;
                })
            }
            
            // Add username to each item
            const filteredDataWithUsername = filteredData.map(item => {
                const user = userMap.get(item.author);
                return user ? { ...item, username: user.username } : item;
            });

            // Process the data
            const processedData = filteredDataWithUsername.map(item => {
                const priceAndType = findPriceAndType(item.body);
                return ({
                    ...item,
                    postdate: new Date(item.postdate),
                    price: priceAndType.price,
                    priceType: priceAndType.type,
                    sold: (item.salesitems && item.saleitems.length > 0) || findSold(item.body),
                    expansion: /\bboardgameexpansion\b/i.test(item.item.href),
                    link: BGG_BASE_URL.concat(item.href),
                })
            });

            // Save to state
            setGeeklistItems(processedData);
            setIsLoading(false);
        } catch (error) {
            console.error('BggList - Error in fetchDataAndProcess:', error);
            setIsLoading(false);
        }
    };

    // const fetchAdditionalXmlData = async () => {
    //     setIsLoading(true);
    //     let loadingMessage = "Loading xml sale data, attempt 1";
    //     setProgressValues({message: loadingMessage, progress: 0, type: "spinner"});
    //     let iterations = 1;
    //     let result = "";
    //     while(true){
    //         result = await fetchResponse(`${xml_base_url}/geeklist/${listIdValue}`)
    //         if (result.status !== 200) {
    //             if (iterations > 15) {
    //                 console.error("Exceeded attempt limit");
    //                 break;
    //             }
                
    //             // wait 5 seconds and try again
    //             await new Promise(resolve => setTimeout(resolve, 5000));

    //             iterations++;
    //             loadingMessage = `Loading xml sale data, attempt ` + iterations;
    //             setProgressValues({message: loadingMessage, progress: 0, type: "spinner"});
    //             continue;
    //         } else {
    //             break;
    //         }
    //     }

    //     if (result.status !== 200) {
    //         console.error("Failed to load xml sale data");
    //         setIsLoading(false);
    //         return;
    //     }

    //     setProgressValues({message: "Parsing sale data", progress: 0, type: "spinner"});
    //     let xmlDoc = await parseXml(result.data);
    //     const xmlItems = xmlDoc.getElementsByTagName('item');
    //     let updatedSaleData = data;
    //     for(let i = 0; i < xmlItems.length; i++) {
    //         const item = xmlItems[i];
    //         const id = item.getAttribute('id');
    //         if(item.getAttribute('sold') === '1') {
    //             let index = updatedSaleData.findIndex(item => {
    //                 return item.id.localeCompare(id) === 0;
    //             });
                    
    //             if(index === -1) {
    //                 console.warn('Could not find data item with id', id);
    //                 continue;
    //             }

    //             updatedSaleData[index].sold = true;
    //         }
    //     }
    //     if(updatedSaleData.length > 0) {
    //         setData(updatedSaleData);
    //     }
    //     setIsLoading(false);
    // }

    const fetchWishlistDataAndProcess = async () => {
        let loadingMessage = "Wishlist attempt 1";
        setProgressValues({message: loadingMessage, progress: 0, type: "spinner"});
        setIsLoading(true);

        let iterations = 1;
        let result = "";
        while(true){
            result = await fetchResponse(`${xml_base_url}/collection?username=${usernameValue}&wishlist=1`)
            if (result.status !== 200) {
                // wait 5 seconds and try again
                await new Promise(resolve => setTimeout(resolve, 5000));
                
                if (iterations > 4) {
                    console.error("Error fetching data from ", xml_base_url);
                    break;
                }
                
                iterations++;
                loadingMessage = `Wishlist attempt ` + iterations;
                setProgressValues({message: loadingMessage, progress: (iterations/5)*100});
                continue;
            } else {
                break;
            }
        }
        
        let xmlDoc = await parseXml(result.data);
        const wishlistItems = xmlDoc.getElementsByTagName('item');
        let wishlistIds = [];

        for(let index = 0; index < wishlistItems.length; index++) {
            wishlistIds.push(wishlistItems[index].getAttribute('objectid'));
        }

        setWishlistData(wishlistIds);
        setIsLoading(false);
    }

    const clearWishlist = () => {
        setWishlistData([]);
    }


    return (
        <div className="app-container">
            <header className="app-header">
                <div className="header-content">
                    <h1 className="app-title">BGG Geeklist Auction Utility</h1>
                    <div className="header-buttons">
                        <button className="header-button" onClick={toggleExpanded}>
                            {isAboutExpanded ? 'Hide Instructions' : 'Instructions'}
                        </button>
                        <button className="header-button" onClick={controlToggleExpanded}>
                            {isControlsExpanded ? 'Hide Controls' : 'Show Controls'}
                        </button>
                    </div>
                </div>
                
                <div className="about-section">
                    {isAboutExpanded && 
                    <div className="about-details">
                        <div className="about-card">
                            <h3 className="about-title">Instructions</h3>
                            <ul className="about-list">
                                <li>Enter the geeklist id you would like to view. Hit the 'Load' button, it takes about a minute to load all the items for a big list.</li>
                                <li>Clicking the column header will sort by that column.</li>
                                <li>The icon button at the right side of the column will allow you to filter. On mobile, tap and hold column headers to access the filters for that column.</li>
                                <li>If you click the button in the 'Details' column it will load a modal of the listing description and comments so you can quickly see if it's sold or what the current bid is.</li>
                                <li>The 'Details' modal also houses the average price of the item based on recent sales data from BGG</li>
                            </ul>
                        </div>
                        
                        <div className="about-card">
                            <h3 className="about-title">Known Issues</h3>
                            <ul className="about-list">
                                <li>Some items are actually sold but render here as not sold. It depends on if the word 'sold' appears in the description. I have some pending work to use the XML endpoint to get the sold property populated by the use of the sold button, but even that is dependent on the actions of the seller, some don't use that button.</li>
                                <li>Some items render with the wrong price listed. It depends on the content of the description. I parse the description for a price, people who offer deals part of their listing make it difficult to have a generic function for identify item price. For instance, "Buy one of my games and get any other for $5 off" appearing below the listed price would cause my function to think it's a $5 item.</li>
                            </ul>
                        </div>
                        
                        <div className="about-footer">
                            Created by Steven Branham (<a href="https://boardgamegeek.com/user/branhammer" className="author-link">Branhammer</a>)
                        </div>
                    </div>
                    }
                </div>
            </header>
            
            <BggGameModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} modalData={modalGameData}/>
            
            {isControlsExpanded && (
                <div className="controls-panel">
                    <div className="control-group">
                        <div className="control-item">
                            <div className="control-input-group">
                                <div ref={dropdownRef} className="dropdown-container" style={{ position: 'relative', width: '100%' }}>
                                    <input 
                                        id="geeklistSearchInput" 
                                        type="text" 
                                        value={searchTerm} 
                                        onChange={handleSearchChange}
                                        onFocus={() => setShowDropdown(true)}
                                        className="control-input"
                                        placeholder='Enter geeklist ID or search by title...'
                                        style={{ width: '100%' }}
                                    />
                                    <div 
                                        className="clear-button" 
                                        onClick={clearGeeklistSearchInput}
                                    >
                                        <i class="fa-solid fa-xmark"></i>
                                    </div>
                                    {showDropdown && (
                                        <div className="dropdown-list" style={{
                                            position: 'absolute',
                                            top: '100%',
                                            left: 0,
                                            width: '100%',
                                            maxHeight: '200px',
                                            overflowY: 'auto',
                                            backgroundColor: 'white',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            zIndex: 10,
                                            boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
                                        }}>
                                            {filteredGeeklists.length > 0 ? (
                                                filteredGeeklists.map(geeklist => (
                                                    <div 
                                                        key={geeklist.id} 
                                                        className="dropdown-item"
                                                        onClick={() => handleGeeklistSelect(geeklist)}
                                                        style={{
                                                            padding: '8px 12px',
                                                            cursor: 'pointer',
                                                            borderBottom: '1px solid #eee',
                                                            hoverBackgroundColor: '#f5f5f5'
                                                        }}
                                                    >
                                                        <div style={{ fontWeight: 'bold' }}>{geeklist.title}</div>
                                                        <div style={{ fontSize: '0.8em', color: '#666' }}>
                                                            ID: {geeklist.id} | QTY: {geeklist.numItems} | Created by: {geeklist.username}
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div style={{ padding: '8px 12px', color: '#666' }}>
                                                    {searchTerm.trim() !== '' ? 'No matching geeklists found' : 'Start typing to search recent geeklists'}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <button 
                                    onClick={fetchDataAndProcess}
                                    className="control-button primary-button"
                                >
                                    Load
                                </button>
                                <button 
                                    className="geeklist-body-toggle control-button primary-button" 
                                    onClick={toggleGeeklistBodyExpanded}
                                    disabled={geeklistItems.length === 0}
                                    title={isGeeklistBodyExpanded ? 'Hide Description' : 'Show Description'}
                                    aria-label={isGeeklistBodyExpanded ? 'Hide Description' : 'Show Description'}
                                >
                                    <i class="fa-solid fa-circle-info"></i> info
                                </button>
                            </div>
                        </div>
                        
                        <div className="control-divider"></div>
                        
                        <div className="control-item">
                            <div className="control-input-group">
                                <input 
                                    id="usernameInput" 
                                    type="text" 
                                    placeholder="BGG Username" 
                                    value={usernameValue} 
                                    onChange={handleUsernameValue}
                                    className="control-input" 
                                />
                                <button 
                                    onClick={fetchWishlistDataAndProcess} 
                                    disabled={geeklistItems.length === 0}
                                    className="control-button"
                                >
                                    Filter by Wishlist
                                </button>
                                <button 
                                    onClick={clearWishlist} 
                                    disabled={wishlistData.length === 0}
                                    className="control-button"
                                >
                                    Clear
                                </button>
                            </div>
                        </div>
                        
                        <div className="control-divider"></div>
                        
                        <div className="control-item seller-list-control">
                            <Link to="/seller-list">
                                <button 
                                    disabled={geeklistItems.length === 0 || usernameValue.length === 0}
                                    className="control-button"
                                >
                                    Sellers List
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
            
            {geeklistMeta && geeklistMeta.body && (
                <div className="geeklist-body-container">
                    {isGeeklistBodyExpanded && (
                        <div className="geeklist-body-content">
                            <h2>{geeklistMeta.name}</h2>
                            <div className="geeklist-body">
                                <BBCodeParser bbcode={geeklistMeta.body} />
                            </div>
                        </div>
                    )}
                </div>
            )}
            
            <div className="table-info">
                <div className="row-count">Number of Rows: {rowCount}</div>
                {isLoading && (
                    <LoadingInline 
                        message={progressValues.message} 
                        progress={progressValues.progress} 
                        type={progressValues.type} 
                    />
                )}
            </div>
            
            <div className="table-container">
                <div className="ag-theme-alpine">
                    <AgGridReact
                        ref={gridRef}
                        columnDefs={columnDefs}
                        autoSizeStrategy={autoSizeStrategy}
                        rowData={geeklistItems}
                        isExternalFilterPresent={isExternalFilterPresent}
                        doesExternalFilterPass={doesExternalFilterPass}
                        onGridReady={updateRowCount}
                        onModelUpdated={updateRowCount}
                        className="data-grid"
                    >
                    </AgGridReact>
                </div>
            </div>
        </div>
    );
};

export default BggList;
