import axios from 'axios';

export const fetchResponse = async (url) => {
    try {
        const response = await axios.get(url);
        return response;
    } catch (error) {
        console.error("Error fetching data from", url);
        return null;
    }
};

export const fetchData = async (url) => {
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error("Error fetching data from", url);
        return null;
    }
};

export const fetchGeeklists = async () => {
    try {
        const url = "/api/geeklists?partial=listing&sort=recent&category=trade&pageid=1&domain=boardgame";
        const response = await axios.get(url);
        
        // Process the response to extract the needed information
        if (response.data && response.data.data) {
            const geeklists = response.data.data.map(geeklist => ({
                id: geeklist.id,
                title: geeklist.name,
                username: geeklist.creator?.username || 'Unknown',
                numItems: geeklist.numitems
            }));
            
            return { geeklists };
        }
        
        return { geeklists: [] };
    } catch (error) {
        console.error("Error fetching geeklist data:", error);
        return { geeklists: [] };
    }
};
